/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * NinjaAPI
 * OpenAPI spec version: 1.0.0
 */
import {
  z as zod
} from 'zod'


/**
 * @summary User List Api
 */
export const userListQueryParams = zod.object({
  "search": zod.string().or(zod.null()).optional(),
  "page": zod.number().min(1).optional()
})

export const userListResponse = zod.object({
  "items": zod.array(zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
})),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * @summary User Create Api
 */
export const userCreateBody = zod.object({
  "email": zod.string().email(),
  "first_name": zod.string(),
  "last_name": zod.string()
})


/**
 * @summary User Get Api
 */
export const userGetParams = zod.object({
  "id": zod.string()
})

export const userGetResponse = zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
})


/**
 * @summary User Update Api
 */
export const userUpdateParams = zod.object({
  "id": zod.string()
})

export const userUpdateBody = zod.object({
  "email": zod.string().email(),
  "first_name": zod.string(),
  "last_name": zod.string()
})

export const userUpdateResponse = zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
})


/**
 * @summary User Delete Api
 */
export const userDeleteParams = zod.object({
  "id": zod.string()
})


/**
 * @summary User Me Api
 */
export const userMeResponse = zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
})


/**
 * @summary Permission List Api
 */
export const permissionListResponseItem = zod.string()
export const permissionListResponse = zod.array(permissionListResponseItem)


/**
 * @summary File Upload Start Api
 */
export const fileUploadStartBody = zod.object({
  "file_name": zod.string(),
  "file_type": zod.string()
})

export const fileUploadStartResponse = zod.object({
  "file_id": zod.string().uuid(),
  "presigned_data": zod.object({
  "url": zod.string(),
  "fields": zod.object({

}).or(zod.null()).optional()
})
})


/**
 * @summary File Upload Finish Api
 */
export const fileUploadFinishBody = zod.object({
  "file_id": zod.string()
})

export const fileUploadFinishResponse = zod.object({
  "id": zod.string().uuid(),
  "file_name": zod.string(),
  "file_type": zod.string(),
  "original_file_name": zod.string(),
  "uploaded_by": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}).or(zod.null()).optional(),
  "created_at": zod.string().datetime(),
  "upload_finished_at": zod.string().datetime().or(zod.null()).optional(),
  "is_valid": zod.boolean(),
  "url": zod.string()
})


/**
 * This endpoint is used to upload a file to the local file system. It is used with the local
backend.
 * @summary File Upload Local Api
 */
export const fileUploadLocalParams = zod.object({
  "file_id": zod.string()
})

export const fileUploadLocalResponse = zod.object({
  "id": zod.string().uuid(),
  "file_name": zod.string(),
  "file_type": zod.string(),
  "original_file_name": zod.string(),
  "uploaded_by": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}).or(zod.null()).optional(),
  "created_at": zod.string().datetime(),
  "upload_finished_at": zod.string().datetime().or(zod.null()).optional(),
  "is_valid": zod.boolean(),
  "url": zod.string()
})


/**
 * Returns the current user's team workspace definition.
 * @summary Workspace Get Api
 */
export const workspaceGetResponse = zod.object({
  "workspace": zod.object({
  "login_redirect_url": zod.string().optional()
})
})


/**
 * View for users to login via email/password.
 * @summary Auth Internal Login Api
 */
export const authInternalLoginBody = zod.object({
  "email": zod.string().email(),
  "password": zod.string()
})


/**
 * View for users to setup their account from the link sent via email.
 * @summary Auth Internal Setup Account Api
 */
export const authInternalSetupAccountBody = zod.object({
  "uid": zod.string(),
  "token": zod.string(),
  "password1": zod.string(),
  "password2": zod.string()
})


/**
 * View for users to change their password.
 * @summary Auth Internal Change Password Api
 */
export const authInternalChangePasswordBody = zod.object({
  "old_password": zod.string(),
  "new_password1": zod.string(),
  "new_password2": zod.string()
})


/**
 * View for users to be sent an email with a link to reset their password.
 * @summary Auth Internal Forgot Password Api
 */
export const authInternalForgotPasswordBody = zod.object({
  "email": zod.string().email()
})


/**
 * View for users to reset their password from a link sent via email.
 * @summary Auth Internal Reset Password Api
 */
export const authInternalResetPasswordBody = zod.object({
  "uid": zod.string(),
  "token": zod.string(),
  "password1": zod.string(),
  "password2": zod.string()
})


/**
 * Return the URI for setting up multi-factor authentication.
 * @summary Mfa Generate Uri Api
 */
export const mfaGenerateUriResponse = zod.object({
  "uri": zod.string()
})


/**
 * Verify the otp is valid for the user.
 * @summary Mda Verify Otp Api
 */
export const mfaVerifyOtpBody = zod.object({
  "otp": zod.string()
})


/**
 * Return the AuthenticationRealm for the given email.
 * @summary Realm Resolve Api
 */
export const realmResolveBody = zod.object({
  "email": zod.string().email()
})

export const realmResolveResponse = zod.object({
  "type": zod.string(),
  "extra": zod.object({
  "redirect_url": zod.string().or(zod.null()).optional()
})
})


/**
 * Update a Realm's configuration.
 * @summary Realm Update Configuration Api
 */
export const realmUpdateConfigurationParams = zod.object({
  "realm_id": zod.string()
})

export const realmUpdateConfigurationBody = zod.object({
  "configuration": zod.object({
  "saml": zod.object({
  "email_domain_allow_list": zod.array(zod.string()).optional(),
  "idp_config": zod.object({
  "entity_id": zod.string(),
  "url": zod.string(),
  "x509cert": zod.string(),
  "attr_user_permanent_id": zod.string(),
  "attr_email": zod.string(),
  "attr_first_name": zod.string(),
  "attr_last_name": zod.string()
})
}).or(zod.null()).optional()
})
})

export const realmUpdateConfigurationResponse = zod.object({
  "id": zod.string().uuid(),
  "type": zod.string(),
  "configuration": zod.object({
  "saml": zod.object({
  "email_domain_allow_list": zod.array(zod.string()).optional(),
  "idp_config": zod.object({
  "entity_id": zod.string(),
  "url": zod.string(),
  "x509cert": zod.string(),
  "attr_user_permanent_id": zod.string(),
  "attr_email": zod.string(),
  "attr_first_name": zod.string(),
  "attr_last_name": zod.string()
})
}).or(zod.null()).optional()
})
})


/**
 * @summary Net Worth List Api
 */
export const netWorthListResponseItem = zod.object({
  "id": zod.string().uuid(),
  "date": zod.string().date(),
  "notes": zod.string(),
  "items": zod.object({
  "id": zod.string().uuid(),
  "amount": zod.number().or(zod.string()),
  "category": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "type": zod.string()
})
})
})
export const netWorthListResponse = zod.array(netWorthListResponseItem)


/**
 * @summary Net Worth Create Api
 */
export const netWorthCreateBody = zod.object({
  "date": zod.string().date(),
  "notes": zod.string().or(zod.null()).optional(),
  "items": zod.array(zod.object({
  "amount": zod.number().or(zod.string()),
  "category_id": zod.string().uuid()
}))
})

export const netWorthCreateResponse = zod.object({
  "id": zod.string().uuid(),
  "date": zod.string().date(),
  "notes": zod.string(),
  "items": zod.object({
  "id": zod.string().uuid(),
  "amount": zod.number().or(zod.string()),
  "category": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "type": zod.string()
})
})
})


/**
 * @summary Net Worth Lsit Api
 */
export const netWorthCategoryListResponseItem = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "type": zod.string()
})
export const netWorthCategoryListResponse = zod.array(netWorthCategoryListResponseItem)


/**
 * @summary Net Worth Create Api
 */
export const netWorthCategoryCreateBody = zod.object({
  "name": zod.string(),
  "type": zod.string()
})

export const netWorthCategoryCreateResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "type": zod.string()
})


/**
 * @summary Transaction List Api
 */
export const transactionListQueryParams = zod.object({
  "search": zod.string().or(zod.null()).optional(),
  "category": zod.string().or(zod.null()).optional(),
  "local_currency": zod.string().or(zod.null()).optional(),
  "dates": zod.string().or(zod.null()).optional(),
  "include_in_stats": zod.boolean().or(zod.null()).optional(),
  "page": zod.number().min(1).optional()
})

export const transactionListResponse = zod.object({
  "items": zod.array(zod.object({
  "id": zod.string().uuid(),
  "transaction_id": zod.string(),
  "timestamp": zod.string().datetime(),
  "transaction_type": zod.string(),
  "amount": zod.number().or(zod.string()),
  "local_amount": zod.number().or(zod.string()).or(zod.null()),
  "local_currency": zod.string(),
  "name": zod.string(),
  "description": zod.string(),
  "category": zod.string(),
  "notes": zod.string(),
  "address": zod.string(),
  "emoji": zod.string(),
  "include_in_stats": zod.boolean()
})),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * @summary Transaction Overall Stats Api
 */
export const transactionOverallStatsQueryParams = zod.object({
  "search": zod.string().or(zod.null()).optional(),
  "category": zod.string().or(zod.null()).optional(),
  "local_currency": zod.string().or(zod.null()).optional(),
  "dates": zod.string().or(zod.null()).optional(),
  "include_in_stats": zod.boolean().or(zod.null()).optional()
})

export const transactionOverallStatsResponse = zod.object({
  "net_amount": zod.number().or(zod.string()).or(zod.null()),
  "max_amount": zod.number().or(zod.string()).or(zod.null()),
  "min_amount": zod.number().or(zod.string()).or(zod.null()),
  "num_transactions": zod.number().or(zod.null())
})


/**
 * @summary Transaction Spending Stats Api
 */
export const transactionSpendingStatsResponse = zod.object({
  "spending_this_week": zod.number().or(zod.string()),
  "spending_this_month": zod.number().or(zod.string()),
  "spending_last_7_days": zod.number().or(zod.string()),
  "spending_last_30_days": zod.number().or(zod.string()),
  "spending_per_week": zod.array(zod.object({
  "week": zod.string().datetime(),
  "total_amount": zod.number().or(zod.string())
})),
  "spending_per_month": zod.array(zod.object({
  "month": zod.string().datetime(),
  "total_amount": zod.number().or(zod.string())
}))
})


/**
 * @summary Transaction Category Stats Api
 */
export const transactionCategoryStatsQueryParams = zod.object({
  "search": zod.string().or(zod.null()).optional(),
  "category": zod.string().or(zod.null()).optional(),
  "local_currency": zod.string().or(zod.null()).optional(),
  "dates": zod.string().or(zod.null()).optional(),
  "include_in_stats": zod.boolean().or(zod.null()).optional()
})

export const transactionCategoryStatsResponseItem = zod.object({
  "category": zod.string(),
  "total_amount": zod.number().or(zod.string())
})
export const transactionCategoryStatsResponse = zod.array(transactionCategoryStatsResponseItem)


/**
 * @summary Transaction Get Api
 */
export const transactionGetParams = zod.object({
  "id": zod.string()
})

export const transactionGetResponse = zod.object({
  "id": zod.string().uuid(),
  "transaction_id": zod.string(),
  "timestamp": zod.string().datetime(),
  "transaction_type": zod.string(),
  "amount": zod.number().or(zod.string()),
  "local_amount": zod.number().or(zod.string()).or(zod.null()),
  "local_currency": zod.string(),
  "name": zod.string(),
  "description": zod.string(),
  "category": zod.string(),
  "notes": zod.string(),
  "address": zod.string(),
  "emoji": zod.string(),
  "include_in_stats": zod.boolean()
})


/**
 * @summary Transaction Update Api
 */
export const transactionUpdateParams = zod.object({
  "id": zod.string()
})

export const transactionUpdateBody = zod.object({
  "include_in_stats": zod.boolean()
})

export const transactionUpdateResponse = zod.object({
  "id": zod.string().uuid(),
  "transaction_id": zod.string(),
  "timestamp": zod.string().datetime(),
  "transaction_type": zod.string(),
  "amount": zod.number().or(zod.string()),
  "local_amount": zod.number().or(zod.string()).or(zod.null()),
  "local_currency": zod.string(),
  "name": zod.string(),
  "description": zod.string(),
  "category": zod.string(),
  "notes": zod.string(),
  "address": zod.string(),
  "emoji": zod.string(),
  "include_in_stats": zod.boolean()
})


/**
 * Get the distinct values of a property on the Transaction model.
 * @summary Transaction Aggregate Field Values Api
 */
export const transactionAggregateFieldValuesParams = zod.object({
  "field_name": zod.string()
})

export const transactionAggregateFieldValuesQueryParams = zod.object({
  "search": zod.string().or(zod.null()).optional(),
  "category": zod.string().or(zod.null()).optional(),
  "local_currency": zod.string().or(zod.null()).optional(),
  "dates": zod.string().or(zod.null()).optional(),
  "include_in_stats": zod.boolean().or(zod.null()).optional(),
  "page": zod.number().min(1).optional()
})

export const transactionAggregateFieldValuesResponse = zod.object({
  "items": zod.array(zod.string()),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * @summary Activity List Api
 */
export const activityListQueryParams = zod.object({
  "page": zod.number().min(1).optional()
})

export const activityListResponse = zod.object({
  "items": zod.array(zod.object({
  "id": zod.string().uuid(),
  "title": zod.string(),
  "notes": zod.string(),
  "date": zod.string().date(),
  "metrics": zod.array(zod.object({
  "id": zod.string().uuid(),
  "metric": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
}),
  "value": zod.number()
}))
})),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * @summary Activity Create Api
 */
export const activityCreateBody = zod.object({
  "title": zod.string(),
  "notes": zod.string().or(zod.null()).optional(),
  "date": zod.string().date(),
  "metrics": zod.array(zod.object({
  "metric_id": zod.string().uuid(),
  "value": zod.number()
}))
})

export const activityCreateResponse = zod.object({
  "id": zod.string().uuid(),
  "title": zod.string(),
  "notes": zod.string(),
  "date": zod.string().date(),
  "metrics": zod.array(zod.object({
  "id": zod.string().uuid(),
  "metric": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
}),
  "value": zod.number()
}))
})


/**
 * @summary Activity Get Api
 */
export const activityGetParams = zod.object({
  "id": zod.string()
})

export const activityGetResponse = zod.object({
  "id": zod.string().uuid(),
  "title": zod.string(),
  "notes": zod.string(),
  "date": zod.string().date(),
  "metrics": zod.array(zod.object({
  "id": zod.string().uuid(),
  "metric": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
}),
  "value": zod.number()
}))
})


/**
 * @summary Activity Update Api
 */
export const activityUpdateParams = zod.object({
  "id": zod.string()
})

export const activityUpdateBody = zod.object({
  "title": zod.string(),
  "notes": zod.string().or(zod.null()).optional(),
  "date": zod.string().date(),
  "metrics": zod.array(zod.object({
  "id": zod.string().uuid(),
  "metric_id": zod.string().uuid(),
  "value": zod.number()
}))
})

export const activityUpdateResponse = zod.object({
  "id": zod.string().uuid(),
  "title": zod.string(),
  "notes": zod.string(),
  "date": zod.string().date(),
  "metrics": zod.array(zod.object({
  "id": zod.string().uuid(),
  "metric": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
}),
  "value": zod.number()
}))
})


/**
 * @summary Activity Delete Api
 */
export const activityDeleteParams = zod.object({
  "id": zod.string()
})


/**
 * @summary Physical Snapshot List Api
 */
export const physicalSnapshotListQueryParams = zod.object({
  "page": zod.number().min(1).optional()
})

export const physicalSnapshotListResponse = zod.object({
  "items": zod.array(zod.object({
  "id": zod.string().uuid(),
  "user": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}),
  "notes": zod.string(),
  "created_at": zod.string().datetime(),
  "metrics": zod.array(zod.object({
  "id": zod.string().uuid(),
  "value": zod.number(),
  "metric": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
})
})),
  "attachments": zod.array(zod.object({
  "id": zod.string().uuid(),
  "file_name": zod.string(),
  "file_type": zod.string(),
  "original_file_name": zod.string(),
  "uploaded_by": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}).or(zod.null()).optional(),
  "created_at": zod.string().datetime(),
  "upload_finished_at": zod.string().datetime().or(zod.null()).optional(),
  "is_valid": zod.boolean(),
  "url": zod.string()
}))
})),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * @summary Physical Snapshot Create Api
 */
export const physicalSnapshotCreateBody = zod.object({
  "notes": zod.string().optional(),
  "metrics": zod.array(zod.object({
  "value": zod.number(),
  "metric_id": zod.string()
})),
  "attachment_ids": zod.array(zod.string())
})

export const physicalSnapshotCreateResponse = zod.object({
  "id": zod.string().uuid(),
  "user": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}),
  "notes": zod.string(),
  "created_at": zod.string().datetime(),
  "metrics": zod.array(zod.object({
  "id": zod.string().uuid(),
  "value": zod.number(),
  "metric": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
})
})),
  "attachments": zod.array(zod.object({
  "id": zod.string().uuid(),
  "file_name": zod.string(),
  "file_type": zod.string(),
  "original_file_name": zod.string(),
  "uploaded_by": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}).or(zod.null()).optional(),
  "created_at": zod.string().datetime(),
  "upload_finished_at": zod.string().datetime().or(zod.null()).optional(),
  "is_valid": zod.boolean(),
  "url": zod.string()
}))
})


/**
 * @summary Physical Snapshot Get Api
 */
export const physicalSnapshotGetParams = zod.object({
  "id": zod.string()
})

export const physicalSnapshotGetResponse = zod.object({
  "id": zod.string().uuid(),
  "user": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}),
  "notes": zod.string(),
  "created_at": zod.string().datetime(),
  "metrics": zod.array(zod.object({
  "id": zod.string().uuid(),
  "value": zod.number(),
  "metric": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
})
})),
  "attachments": zod.array(zod.object({
  "id": zod.string().uuid(),
  "file_name": zod.string(),
  "file_type": zod.string(),
  "original_file_name": zod.string(),
  "uploaded_by": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}).or(zod.null()).optional(),
  "created_at": zod.string().datetime(),
  "upload_finished_at": zod.string().datetime().or(zod.null()).optional(),
  "is_valid": zod.boolean(),
  "url": zod.string()
}))
})


/**
 * @summary Physical Snapshot Delete Api
 */
export const physicalSnapshotDeleteParams = zod.object({
  "id": zod.string()
})


/**
 * Return all all of the metrics with
 * @summary Physical Snapshot Metric Stats Api
 */
export const physicalSnapshotMetricStatsResponseItem = zod.object({
  "id": zod.string().uuid(),
  "physical_snapshot_id": zod.string().uuid(),
  "date": zod.string().date(),
  "metric": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
}),
  "value": zod.number()
})
export const physicalSnapshotMetricStatsResponse = zod.array(physicalSnapshotMetricStatsResponseItem)


/**
 * @summary Metric List Api
 */
export const metricListQueryParams = zod.object({
  "name": zod.string().or(zod.null()).optional(),
  "unit": zod.string().or(zod.null()).optional(),
  "type": zod.string().or(zod.null()).optional()
})

export const metricListResponseItem = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
})
export const metricListResponse = zod.array(metricListResponseItem)


/**
 * @summary Metric Create Api
 */
export const metricCreateBody = zod.object({
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
})

export const metricCreateResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
})


/**
 * @summary Metric Update Api
 */
export const metricUpdateParams = zod.object({
  "id": zod.string()
})

export const metricUpdateBody = zod.object({
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
})

export const metricUpdateResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "unit": zod.string(),
  "type": zod.string()
})


/**
 * @summary User List Api
 */
export const userAdminListQueryParams = zod.object({
  "search": zod.string().or(zod.null()).optional(),
  "team_id": zod.string().or(zod.null()).optional(),
  "user_ids": zod.array(zod.string()).or(zod.null()).optional(),
  "exclude_user_ids": zod.array(zod.string()).or(zod.null()).optional(),
  "page": zod.number().min(1).optional()
})

export const userAdminListResponse = zod.object({
  "items": zod.array(zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "last_login": zod.string().datetime().or(zod.null()),
  "date_joined": zod.string().datetime(),
  "is_active": zod.boolean()
})),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * @summary User Create Api
 */
export const userAdminCreateBody = zod.object({
  "team_id": zod.string(),
  "email": zod.string().email(),
  "first_name": zod.string(),
  "last_name": zod.string()
})


/**
 * @summary User Get Api
 */
export const userAdminGetParams = zod.object({
  "id": zod.string()
})

export const userAdminGetResponse = zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "last_login": zod.string().datetime().or(zod.null()),
  "date_joined": zod.string().datetime(),
  "is_active": zod.boolean()
})


/**
 * @summary User Update Api
 */
export const userAdminUpdateParams = zod.object({
  "id": zod.string()
})

export const userAdminUpdateBody = zod.object({
  "email": zod.string().email(),
  "first_name": zod.string(),
  "last_name": zod.string()
})

export const userAdminUpdateResponse = zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "last_login": zod.string().datetime().or(zod.null()),
  "date_joined": zod.string().datetime(),
  "is_active": zod.boolean()
})


/**
 * @summary User Delete Api
 */
export const userAdminDeleteParams = zod.object({
  "id": zod.string()
})


/**
 * @summary Group List Api
 */
export const groupListQueryParams = zod.object({
  "search": zod.string().or(zod.null()).optional(),
  "team_id": zod.string().or(zod.null()).optional()
})

export const groupListResponseItem = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "permissions": zod.array(zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
})),
  "users": zod.array(zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}))
})
export const groupListResponse = zod.array(groupListResponseItem)


/**
 * @summary Group Create Api
 */
export const groupCreateBody = zod.object({
  "team_id": zod.string(),
  "name": zod.string()
})


/**
 * @summary Group List For User Api
 */
export const groupListForUserParams = zod.object({
  "user_id": zod.string()
})

export const groupListForUserResponseItem = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "permissions": zod.array(zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
})),
  "users": zod.array(zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}))
})
export const groupListForUserResponse = zod.array(groupListForUserResponseItem)


/**
 * @summary Group Get Api
 */
export const groupGetParams = zod.object({
  "id": zod.string()
})

export const groupGetResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "permissions": zod.array(zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
})),
  "users": zod.array(zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}))
})


/**
 * @summary Group Update Api
 */
export const groupUpdateParams = zod.object({
  "id": zod.string()
})

export const groupUpdateBody = zod.object({
  "name": zod.string()
})

export const groupUpdateResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "permissions": zod.array(zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
})),
  "users": zod.array(zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}))
})


/**
 * @summary Group Update Permissions Api
 */
export const groupUpdatePermissionsParams = zod.object({
  "id": zod.string()
})

export const groupUpdatePermissionsBody = zod.object({
  "permissions": zod.record(zod.string(), zod.boolean())
})

export const groupUpdatePermissionsResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "permissions": zod.array(zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
})),
  "users": zod.array(zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}))
})


/**
 * @summary Group Update Members Api
 */
export const groupUpdateUsersParams = zod.object({
  "id": zod.string()
})

export const groupUpdateUsersBody = zod.object({
  "users": zod.record(zod.string(), zod.boolean())
})

export const groupUpdateUsersResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "permissions": zod.array(zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
})),
  "users": zod.array(zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}))
})


/**
 * @summary Group Delete Api
 */
export const groupDeleteParams = zod.object({
  "id": zod.string()
})


/**
 * @summary Permission Admin List Api
 */
export const permissionAdminListResponseItem = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
})
export const permissionAdminListResponse = zod.array(permissionAdminListResponseItem)


/**
 * @summary Team List Api
 */
export const teamListQueryParams = zod.object({
  "search": zod.string().or(zod.null()).optional(),
  "realm": zod.string().or(zod.null()).optional(),
  "is_mfa_enabled": zod.string().or(zod.null()).optional(),
  "page": zod.number().min(1).optional()
})

export const teamListResponse = zod.object({
  "items": zod.array(zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "realm": zod.object({
  "id": zod.string().uuid(),
  "type": zod.string(),
  "configuration": zod.object({
  "saml": zod.object({
  "email_domain_allow_list": zod.array(zod.string()).optional(),
  "idp_config": zod.object({
  "entity_id": zod.string(),
  "url": zod.string(),
  "x509cert": zod.string(),
  "attr_user_permanent_id": zod.string(),
  "attr_email": zod.string(),
  "attr_first_name": zod.string(),
  "attr_last_name": zod.string()
})
}).or(zod.null()).optional()
})
}),
  "mfa_configuration": zod.object({
  "id": zod.string().uuid(),
  "is_enabled": zod.boolean(),
  "session_expiry_seconds": zod.number()
})
})),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * @summary Team Create Api
 */
export const teamCreateBody = zod.object({
  "name": zod.string()
})


/**
 * @summary Team Get Api
 */
export const teamGetParams = zod.object({
  "id": zod.string()
})

export const teamGetResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "realm": zod.object({
  "id": zod.string().uuid(),
  "type": zod.string(),
  "configuration": zod.object({
  "saml": zod.object({
  "email_domain_allow_list": zod.array(zod.string()).optional(),
  "idp_config": zod.object({
  "entity_id": zod.string(),
  "url": zod.string(),
  "x509cert": zod.string(),
  "attr_user_permanent_id": zod.string(),
  "attr_email": zod.string(),
  "attr_first_name": zod.string(),
  "attr_last_name": zod.string()
})
}).or(zod.null()).optional()
})
}),
  "mfa_configuration": zod.object({
  "id": zod.string().uuid(),
  "is_enabled": zod.boolean(),
  "session_expiry_seconds": zod.number()
})
})


/**
 * @summary Team Update Api
 */
export const teamUpdateParams = zod.object({
  "id": zod.string()
})

export const teamUpdateBody = zod.object({
  "name": zod.string()
})

export const teamUpdateResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "realm": zod.object({
  "id": zod.string().uuid(),
  "type": zod.string(),
  "configuration": zod.object({
  "saml": zod.object({
  "email_domain_allow_list": zod.array(zod.string()).optional(),
  "idp_config": zod.object({
  "entity_id": zod.string(),
  "url": zod.string(),
  "x509cert": zod.string(),
  "attr_user_permanent_id": zod.string(),
  "attr_email": zod.string(),
  "attr_first_name": zod.string(),
  "attr_last_name": zod.string()
})
}).or(zod.null()).optional()
})
}),
  "mfa_configuration": zod.object({
  "id": zod.string().uuid(),
  "is_enabled": zod.boolean(),
  "session_expiry_seconds": zod.number()
})
})


/**
 * @summary Team Delete Api
 */
export const teamDeleteParams = zod.object({
  "id": zod.string()
})


/**
 * @summary Secret List Api
 */
export const secretListParams = zod.object({
  "team_id": zod.string()
})

export const secretListQueryParams = zod.object({
  "page": zod.number().min(1).optional()
})

export const secretListResponse = zod.object({
  "items": zod.array(zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "description": zod.string(),
  "created_by": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}),
  "created_at": zod.string().datetime(),
  "updated_by": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}),
  "updated_at": zod.string().datetime()
})),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * @summary Secret Create Api
 */
export const secretCreateParams = zod.object({
  "team_id": zod.string()
})

export const secretCreateBody = zod.object({
  "name": zod.string(),
  "description": zod.string().or(zod.null()).optional(),
  "value": zod.string()
})

export const secretCreateResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "description": zod.string(),
  "created_by": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}),
  "created_at": zod.string().datetime(),
  "updated_by": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}),
  "updated_at": zod.string().datetime()
})


/**
 * @summary Secret Update Api
 */
export const secretUpdateParams = zod.object({
  "team_id": zod.string(),
  "id": zod.string()
})

export const secretUpdateBody = zod.object({
  "name": zod.string(),
  "description": zod.string().or(zod.null()).optional(),
  "value": zod.string()
})

export const secretUpdateResponse = zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "description": zod.string(),
  "created_by": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}),
  "created_at": zod.string().datetime(),
  "updated_by": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}),
  "updated_at": zod.string().datetime()
})


/**
 * @summary Secret Delete Api
 */
export const secretDeleteParams = zod.object({
  "team_id": zod.string(),
  "id": zod.string()
})


/**
 * @summary Run Task Api
 */
export const runTaskParams = zod.object({
  "task_name": zod.string()
})

export const runTaskBody = zod.object({
  "kwargs": zod.object({

})
})


/**
 * @summary Auth Token List For User Api
 */
export const authTokenListForUserParams = zod.object({
  "user_id": zod.string()
})

export const authTokenListForUserQueryParams = zod.object({
  "page": zod.number().min(1).optional()
})

export const authTokenListForUserResponse = zod.object({
  "items": zod.array(zod.object({
  "id": zod.string().uuid(),
  "created_at": zod.string().datetime(),
  "expiry": zod.string().datetime(),
  "is_valid": zod.boolean()
})),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * @summary Auth Token Revolk All For User Api
 */
export const authTokenRevolkAllForUserParams = zod.object({
  "user_id": zod.string()
})


/**
 * @summary Mfa Team Configuration Update Api
 */
export const mfaTeamConfigurationUpdateParams = zod.object({
  "id": zod.string()
})

export const mfaTeamConfigurationUpdateBody = zod.object({
  "is_enabled": zod.boolean(),
  "session_expiry_seconds": zod.number().or(zod.null()).optional()
})

export const mfaTeamConfigurationUpdateResponse = zod.object({
  "id": zod.string().uuid(),
  "is_enabled": zod.boolean(),
  "session_expiry_seconds": zod.number()
})


/**
 * @summary Workspace Get Api
 */
export const workspaceAdminGetParams = zod.object({
  "team_id": zod.string()
})

export const workspaceAdminGetResponse = zod.object({
  "id": zod.string().uuid(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "definition": zod.object({
  "workspace": zod.object({
  "login_redirect_url": zod.string().optional()
})
})
})


/**
 * @summary Workspace Update Api
 */
export const workspaceAdminUpdateParams = zod.object({
  "team_id": zod.string()
})

export const workspaceAdminUpdateBody = zod.object({
  "definition": zod.object({
  "workspace": zod.object({
  "login_redirect_url": zod.string().optional()
})
})
})

export const workspaceAdminUpdateResponse = zod.object({
  "id": zod.string().uuid(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "definition": zod.object({
  "workspace": zod.object({
  "login_redirect_url": zod.string().optional()
})
})
})


/**
 * @summary Event Log List For User Api
 */
export const eventLogListForUserParams = zod.object({
  "user_id": zod.string()
})

export const eventLogListForUserQueryParams = zod.object({
  "page": zod.number().min(1).optional()
})

export const eventLogListForUserResponse = zod.object({
  "items": zod.array(zod.object({
  "id": zod.string().uuid(),
  "user": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}),
  "action": zod.string(),
  "created_at": zod.string().datetime(),
  "object_id": zod.string().uuid().or(zod.null()).optional(),
  "content_type": zod.string().or(zod.null()).optional()
})),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * This endpoint may be considered unsafe as there is no checking that the user has access to the
underlying resource. Therefore, the `EVENT_LOG_VIEW_PERMISSION` permission should only be
assigned to admins.
 * @summary Event Log List For Resource Api
 */
export const eventLogListForResourceParams = zod.object({
  "resource_name": zod.string(),
  "resource_id": zod.string()
})

export const eventLogListForResourceQueryParams = zod.object({
  "page": zod.number().min(1).optional()
})

export const eventLogListForResourceResponse = zod.object({
  "items": zod.array(zod.object({
  "id": zod.string().uuid(),
  "user": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string()
}),
  "action": zod.string(),
  "created_at": zod.string().datetime(),
  "object_id": zod.string().uuid().or(zod.null()).optional(),
  "content_type": zod.string().or(zod.null()).optional()
})),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * @summary Integration List Api
 */
export const integrationListResponseItem = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "description": zod.string(),
  "configuration": zod.record(zod.string(), zod.object({
  "type": zod.enum(['string']),
  "string": zod.object({

})
}).or(zod.object({
  "type": zod.enum(['secret']),
  "secret": zod.object({
  "value_type": zod.enum(['json']).or(zod.null()).optional()
})
})))
})
export const integrationListResponse = zod.array(integrationListResponseItem)


/**
 * @summary Integration Get Api
 */
export const integrationGetParams = zod.object({
  "id": zod.string()
})

export const integrationGetResponse = zod.object({
  "id": zod.string(),
  "name": zod.string(),
  "description": zod.string(),
  "configuration": zod.record(zod.string(), zod.object({
  "type": zod.enum(['string']),
  "string": zod.object({

})
}).or(zod.object({
  "type": zod.enum(['secret']),
  "secret": zod.object({
  "value_type": zod.enum(['json']).or(zod.null()).optional()
})
})))
})


/**
 * @summary Integration Instance List Api
 */
export const integrationInstanceListQueryParams = zod.object({
  "page": zod.number().min(1).optional()
})

export const integrationInstanceListResponse = zod.object({
  "items": zod.array(zod.object({
  "id": zod.string().uuid(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "integration_id": zod.string(),
  "instance_configuration": zod.object({

}),
  "is_enabled": zod.boolean(),
  "crontab": zod.string().or(zod.null()),
  "schedule": zod.string().or(zod.null())
})),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * @summary Integration Instance Create Api
 */
export const integrationInstanceCreateBody = zod.object({
  "integration_id": zod.string(),
  "team_id": zod.string().uuid(),
  "instance_configuration": zod.object({

}),
  "crontab": zod.string().or(zod.null()).optional()
})

export const integrationInstanceCreateResponse = zod.object({
  "id": zod.string().uuid(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "integration_id": zod.string(),
  "instance_configuration": zod.object({

}),
  "is_enabled": zod.boolean(),
  "crontab": zod.string().or(zod.null()),
  "schedule": zod.string().or(zod.null())
})


/**
 * @summary Integration Instance Get Api
 */
export const integrationInstanceGetParams = zod.object({
  "id": zod.string()
})

export const integrationInstanceGetResponse = zod.object({
  "id": zod.string().uuid(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "integration_id": zod.string(),
  "instance_configuration": zod.object({

}),
  "is_enabled": zod.boolean(),
  "crontab": zod.string().or(zod.null()),
  "schedule": zod.string().or(zod.null())
})


/**
 * @summary Integration Instance Delete Api
 */
export const integrationInstanceDeleteParams = zod.object({
  "id": zod.string()
})


/**
 * @summary Integration Instance Update Api
 */
export const integrationInstanceUpdateDetailsParams = zod.object({
  "id": zod.string()
})

export const integrationInstanceUpdateDetailsBody = zod.object({
  "is_enabled": zod.boolean(),
  "crontab": zod.string().or(zod.null()).optional()
})

export const integrationInstanceUpdateDetailsResponse = zod.object({
  "id": zod.string().uuid(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "integration_id": zod.string(),
  "instance_configuration": zod.object({

}),
  "is_enabled": zod.boolean(),
  "crontab": zod.string().or(zod.null()),
  "schedule": zod.string().or(zod.null())
})


/**
 * @summary Integration Instance Update Config Api
 */
export const integrationInstanceUpdateConfigParams = zod.object({
  "id": zod.string()
})

export const integrationInstanceUpdateConfigBody = zod.object({
  "instance_configuration": zod.object({

})
})

export const integrationInstanceUpdateConfigResponse = zod.object({
  "id": zod.string().uuid(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "integration_id": zod.string(),
  "instance_configuration": zod.object({

}),
  "is_enabled": zod.boolean(),
  "crontab": zod.string().or(zod.null()),
  "schedule": zod.string().or(zod.null())
})


/**
 * @summary Integration Instance Manually Run Api
 */
export const integrationInstanceManuallyRunParams = zod.object({
  "id": zod.string()
})


/**
 * @summary Integration Instance Execution List Api
 */
export const integrationInstanceExecutionListForIntegrationInstanceParams = zod.object({
  "integration_instance_id": zod.string()
})

export const integrationInstanceExecutionListForIntegrationInstanceQueryParams = zod.object({
  "page": zod.number().min(1).optional()
})

export const integrationInstanceExecutionListForIntegrationInstanceResponse = zod.object({
  "items": zod.array(zod.object({
  "id": zod.string().uuid(),
  "integration_instance_id": zod.string().uuid(),
  "started_at": zod.string().datetime(),
  "ended_at": zod.string().datetime().or(zod.null()),
  "status": zod.string(),
  "input_data": zod.object({

}).or(zod.null()),
  "exception": zod.string().or(zod.null())
})),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


/**
 * @summary Search Api
 */
export const controlPanelSearchQueryParams = zod.object({
  "q": zod.string(),
  "page": zod.number().min(1).optional()
})

export const controlPanelSearchResponse = zod.object({
  "items": zod.array(zod.object({
  "type": zod.enum(['user']),
  "user": zod.object({
  "id": zod.string().uuid(),
  "email": zod.string(),
  "first_name": zod.string(),
  "last_name": zod.string(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
}),
  "last_login": zod.string().datetime().or(zod.null()),
  "date_joined": zod.string().datetime(),
  "is_active": zod.boolean()
})
}).or(zod.object({
  "type": zod.enum(['team']),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
})
})).or(zod.object({
  "type": zod.enum(['group']),
  "group": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string(),
  "team": zod.object({
  "id": zod.string().uuid(),
  "name": zod.string()
})
})
}))),
  "current": zod.number(),
  "next": zod.number().or(zod.null()),
  "prev": zod.number().or(zod.null())
})


